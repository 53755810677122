@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
.header {
	position: fixed;
	z-index: 10;
	width: 100%;
	height: 100px;
	background: $white;
	border-bottom: 1px solid #e6e6e6;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	top: 0;
	left: 0;
	
	@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
		height: 80px;
	}
	
	&__logo {
		width: 240px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 3px solid $textColor;
		padding: 0 30px;
		@include transition;
		
		&:hover {
			@include opacity;
		}
		@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
			height: 80px;
		}
		@media screen and (min-width: 767.5px) and (max-width:999.5px) {
			width: 200px;
		}
		
		a {
			width: 100%;
		}
	}	

	.gnav {
		display: flex;
		
		&__item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100px;
			padding: 0 15px;
			text-decoration: none;
			@include transition;
			
			@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
				height: 80px;
			}
			@media screen and (min-width: 999.5px) and (max-width: 1260px) {
				padding: 0 10px;
			}
			@media screen and (min-width: 767.5px) and (max-width: 999.4px) {
				padding: 0 6px;
			}
			
			&__text {
				font-size: $m;
				color: $textColor !important;
				text-align: center;
				display: block;
			
				@media screen and (min-width:  999.5px) and (max-width:1260px) {
					font-size: $s;
				}
				@media screen and (min-width:  767.5px) and (max-width:999.4px) {
					font-size: $xs;
				}
			}
			
			&::before {
				position: absolute;
				left: 50%;
				top: 0;
				z-index: 5;
				content: "";
				width: 0;
				height: 3px;
				background: $textColor;
				backface-visibility: hidden;
				transform: translateX(-50%);
				@include transition;
			}
			&:hover {
				color: $textColor !important;
				text-decoration: none !important;
			}
			&:hover::before {
				width: 100%;
			}
			
			&--login {
				background: $blue;
				color: $white !important;
				padding: 0 25px;
				flex-direction: column;
				
				.gnav__item__text {
					color: $white !important;
				}
				.gnav__item__text__icon {
					font-size: $xxl;
					margin: 0 0 5px;
				}
				&::before {
					display: none;
				}
				&:hover {
					color: $white !important;
					text-decoration: none !important;
					background: #076882;
				}
			}
			
			&--search {
				background: $textColor;
				padding: 0 30px;
				cursor: pointer;
				
				.gnav__item__text {
					color: $white !important;
					font-size: 3.2rem;
					line-height: 1.4;
				}
				
				&::before {
					display: none;
				}
				&:hover {
					background: $black;
				}
			}
		}
	}
	
	.header-search {
		&__inner {
			position: absolute;
			top: 100px;
			left: 0;
			right: 0;
			z-index: -1;
			background: rgba(0,0,0,.85);
			opacity: 0;
			transform: translateY(-100%);
			@include transition;
		}
		&__body {
			position: relative;
			display: flex;
			align-items: center;
			max-width: 1000px;
			height: 130px;
			margin: 0 auto;
		}
		&__input[type="text"] {
			width: calc(100% - 50px);
			margin: 0 50px 0 0;
			padding: 5px 15px;
			height: auto;
			background: rgba($white, 0.8);
			border: none;
			color: textColor;
			font-size: $l;

			&::placeholder {
				color: $textColor;
			}
			&:focus {
				border: none;

				&::placeholder {
					color: transparent;
				}
			}
		}
		&__submit {
			right: 0;
			width: 50px;
			background: none;
			color: $white;
			font-size: $xxl;
			background: $blue;
			width: 42px;
			height: 42px;
			@include centering-elements(false, true);
		}
	}
	
	
	&.search-show {
		&::before {
			opacity: 1;
			visibility: visible;
		}
		.header-search__inner {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}	
}


