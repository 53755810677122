@charset "utf-8";

&-registration {
	display: flex;
	
	&__item {
		background: $white;
		padding: 8px 15px;
		width: calc(50% - 5px);
		text-align: center;
		border: 1px solid #e6e6e6;
		
		&:nth-child(even) {
			margin: 0 0 0 10px;
		}
		@media screen and (min-width: 767.5px) and (max-width: 1240.4px) {
			padding: 8px 10px;
			font-size: $s;
		}
	}
	&__txt {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		
		.__ico {
			margin: 0 3px 0 0;
			font-size: $l;
		}
	}
	&__number {
		display: block;
		line-height: 1.4;
		.__elem {
			font-size: $xxxl;
			font-weight: bold;
		}
	}
}
@media print, screen and (min-width: 767.5px) {

}

@media screen and (max-width: 767.499px) {
	&-registration {
		&__item {
			width: calc(50% - 2.5px);
			&:nth-child(even) {
				margin: 0 0 0 5px;
			}
		}
		&__txt {
			line-height: 1;
			font-size: $xs;

			.__ico {
				margin: 0 3px 0 0;
				font-size: $m;
			}
		}
		&__number {
			.__elem {
				font-size: $xxl;
			}
		}
	}
}

