@charset "utf-8";
/* ============================================================ */
/* .about */
/* ============================================================ */
.about {
	
	.catchbox {
		display: flex;
		justify-content: center;
		margin: 0 0 40px;
		
		&--reverse {
			flex-direction: row-reverse;
		}
		
		&__pic {
			width: 50%;
		}
		
		&__message {
			width: 50%;
			background: $white;
			display: flex;
			align-items: center;
			justify-content: center;
			
			&__text {
				text-align: center;
				font-size: $xl;
				position: relative;
				
				&::before,
				&::after {
					content: "\e924";
					font-family: 'icomoon';
					font-size: 6.0rem;
					line-height: 1;
					color: #dadada;
					display: inline-block;
					position: absolute;
				}

				&::before {
					transform: scale(-1, -1);
					left: -60px;
					top: -45px;
				}
				&::after {
					right: -55px;
					top: -45px;
				}
			}
		}
		
		@media (max-width: 1030.5px) and (min-width: 767.5px) {
			
			.catchbox__message {
				position: relative;
				
				&::before,
				&::after {
					content: "\e924";
					font-family: 'icomoon';
					font-size: 6.0rem;
					line-height: 1;
					color: #dadada;
					display: inline-block;
					position: absolute;
				}

				&::before {
					transform: scale(-1, -1);
					left: 20px;
					top: 20px;
				}
				&::after {
					right: 25px;
					top: 20px;
				}

				&__text {
					&::before,
					&::after {
						display: none;
					}
				}
			}
		}
		
	}
	
}

