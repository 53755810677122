@charset "utf-8";
/* ============================================================ */
/* mailuser */
/* ============================================================ */
.mailuser {
	.form-area {
		max-width: 800px;
		margin: 50px auto 0;
		border: 1px solid #dcdcdc;
		padding: 50px 30px;
		background: #e6e6e6;
		
		&__item {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin: 25px 0 0;
			
			&:first-child {
				margin-top: 0;
			}
			
			&__label {
				width: 12em;
				font-weight: 500;
				position: relative;
				margin: 5px 0 0;
				
				&__must {
					background: $textColor;
					color: $white;
					font-size: $xxxs;
					margin: 0 10px 0 0;
					padding: 1px 8px;
				}
			}
			&__input {
				width: calc(100% - 14em);
			}
		}
	}
}

