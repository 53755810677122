@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: $white;
	border-bottom: 1px solid #e6e6e6;
	padding: 0 0 0 15px;
	z-index: 10;
	display: flex;
	align-items: center;
	height: 60px;

	&__logo {
		flex: 1 1 auto;
		line-height: 0;
	}
	
	&__login {
		background: $blue;
		color: $white !important;
		font-size: $xxxs;
		text-decoration: none !important;
		width: 60px;
		height: 60px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		
		&__icon {
			font-size: $xl;
			margin: 0 0 2px;
		}
	}
	
	&__btn {
		background: $black;
		width: 60px;
		height: 60px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
	
	&__menu {
		position: relative;
		width: 28px;
		height: 20px;
		z-index: 10;
		cursor: pointer;
		@include transition;

		&__item {
			display: inline-block;
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background: $white;
			@include transition;
			@include radius(2);

			&:nth-of-type(1) { top: 0; }
			&:nth-of-type(2) { top: 9px; }
			&:nth-of-type(3) { bottom: 0; }
		}
		&.active {
			.header__menu__item {
				&:nth-of-type(1) { transform: translateY(9px) rotate(-315deg); }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-9px) rotate(315deg); } 
			}
		}
	}

	.gnav {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100vh;
		padding: 80px 0 45px;
		background: rgba(#000, 0.93);
		opacity: 0;
		visibility: hidden;
		@include transition;

		&__item {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-direction: row-reverse;
			padding: 15px 0 15px 20px;
			border-bottom: 1px solid rgba($white, 0.15);
			text-decoration: none;
			position: relative;
			
			&:first-child {
				border-top: 1px solid rgba($white, 0.15);
			}
			
			&__text {
				color: $white;
				font-size: $m;
				line-height: 1.2;
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
		}
		
		.show & {
			z-index: 1;
			opacity: 1;
			visibility: visible;
		}
	}
	
	.header-search {
		padding: 20px 20px 0;
		
		&__body {
			position: relative;
			display: flex;
			align-items: center;
			max-width: 100%;
		}
		&__input[type="text"] {
			width: calc(100% - 50px);
			margin: 0 50px 0 0;
			padding: 5px 15px;
			height: auto;
			background: $white;
			border: none;
			color: textColor;
			font-size: $l;

			&::placeholder {
				color: $textColor;
			}
			&:focus {
				border: none;

				&::placeholder {
					color: transparent;
				}
			}
		}
		&__submit {
			right: 0;
			width: 50px;
			background: none;
			color: $white;
			font-size: $xxl;
			background: $blue;
			width: 42px;
			height: 42px;
			@include centering-elements(false, true);
		}
	}
}

