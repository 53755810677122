@charset "utf-8";
/* ============================================================ */
/* .company */
/* ============================================================ */

/* 企業情報詳細ページのタイトル
/* --------------------------------------------------- */
.ttl-company {
	border-bottom: 1px solid $borderColor;
	margin: 0 0 15px;
	padding: 0 0 30px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	
	@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
		padding: 0 0 25px;
		flex-wrap: wrap;
	}
	&::after {
		content: "";
		width: 80px;
		height: 1px;
		border-bottom: 1px solid $textColor;
		position: absolute;
		bottom: -1px;
		left: 0;
	}
	
	&__inner {
		font-size: 3.2rem;
		font-weight: 500;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex: 1;

		@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
			flex: 1 0 auto;
		}
	}
	&__code {
		font-size: $xl;
		font-family: 'Montserrat', sans-serif;
		margin: 5px 0 0;
		padding: 0 60px 0 0;
		position: relative;

		&::after {
			content: "";
			border-top: 1px solid $textColor;
			width: 25px;
			height: 1px;
			display: inline-block;
			position: absolute;
			right: 18px;
			top: calc(50% - 1px);
		}
	}
	&__name {
		line-height: 1.4;
		
		&__en {
			display: block;
			font-size: $s;
			color: #8F8F8F;
			font-family: 'Montserrat', sans-serif;
			margin: 15px 0 0;
		}
	}
	
	&__btn {
		margin-left: auto;
		padding: 0 0 0 15px;

		@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
			width: 100%;
			padding: 0;
			margin: 15px 0 0;
			text-align: right;
		}
	}
	
	&__message {
		background: $textColor;
		position: absolute;
		top: -60px;
		width: 100%;
		font-size: 1.4rem;
		text-align: center;
		color: $white;
		padding: 3px 0;
		opacity: 1;
		@include transition;
		
		@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
			top: -50px;
		}
		&__inner {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}
		&__btn {
			position: absolute;
			top: 0;
			right: 10px;
			cursor: pointer;
			@include transition;
			background: none;
			color: $white;
			
			&:hover {
				@include opacity;
			}
		}
		
		&--close {
			opacity: 0;
			pointer-events: none;
		}
	}
}


.company {
	.company-information {
		&__item {
			&__content {
				&__relation-link {
					display: flex;
					flex-wrap: wrap;
					
					&__item {
						margin: 0 30px 0 0;
						padding: 0 18px 0 15px;
						position: relative;
						display: inline-block;

						&::before {
							content: "\e90a";
							font-family: 'icomoon' !important;
							font-size: $s;
							position: absolute;
							left: 0;
							top: 2px;
						}
						&::after {
							content: "\e90c";
							font-family: 'icomoon' !important;
							font-size: 1.25rem;
							position: absolute;
							right: 0;
							top: 4px;
						}
					}
				}
			}
		}
		
		&__right {
			border-bottom: 1px solid $borderColor;
			
			.company-information__item {
				border-bottom: none;
			}
		}
	}

}
