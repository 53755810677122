@charset "utf-8";
/* ============================================================ */
/* .watchlist */
/* ============================================================ */
.watchlist {
	
	.watchlist-list {
		margin: 0 auto;
		
		&__item {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			border-top: 1px solid $borderColor;
			padding: 15px 0;
			
			&:first-child {
				border-top: none;
			}
			
			input[type="checkbox"] {
				display: none;
			}
			
			input[type="checkbox"] + .watchlist-list__item__company {
				position: relative;
				padding: 0 0 0 30px;
				color: $textColor;
				cursor: pointer;

				&::before,
				&::after {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
				&::before {
					left: 0;
					content: '';
					width: 15px;
					height: 15px;
					background: #fff;
					border: 2px solid darken($borderColor, 5%);
				}
				&::after {
					left: 1px;
					content: "\e91f";
					color: $blue;
					font-family: 'icomoon';
					font-size: $m;
					opacity: 0;
				}
			}
			input[type="checkbox"]:checked + .watchlist-list__item__company {
				&::after {
					opacity: 1;
				}
			}			
			
			&__company {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				
				&__code {
					margin: 0 15px 0 0;
					font-weight: 500;
				}
			}
			
			&__btn {
				margin-left: auto;
			}
		}
	}
	
	.md-overlay {
		z-index: 10;
	}
	
	.mail-setting {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 75%;
		margin: 0 auto;
		padding: 30px;
		border: 1px solid #dcdcdc;
		background: #e6e6e6;
		
		&__radio {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			
			.radio {
				margin: 0 40px 0 0;
			}
		}
		
		& + .wrap-ttl-btn {
			border-top: 1px solid #dcdcdc;
			padding-top: 60px;
			margin-bottom: 25px;
			margin-top: 60px;
		}
	}
}

