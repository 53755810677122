@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer {
	color: #e3e3e3;
	text-align: center;
	line-height: 1.6;
	
	.inner {
		width: 100%;
		padding: 0 15px;
	}
	
	&__top {
		background: #484848;
		
		.inner {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 25px 0;
		}
		
		&__logo {
			width: 150px;
		}
		
		&__sns {
			margin: 15px 0 0;
			
			&__item {
				width: 35px;
				height: 35px;
				text-decoration: none;
				background: $white;
				color: $textColor !important;
				@include radius(18);
				display: inline-flex;
				justify-content: center;
				align-items: center;
				margin: 0 10px 0 0;
			}
		}
		
		&__search {
			border: 1px solid #727272;
			@include radius(4);
			margin: 20px 0 0;
			padding: 8px;
			
			&__body {
				position: relative;
				display: flex;
				align-items: center;
				
				&__input[type="text"] {
					width: calc(100% - 50px);
					margin: 0 50px 0 0;
					padding: 0 0 0 10px;
					height: auto;
					background: #484848;
					border: none;
					color: $white;
					font-size: $xxs;

					&::placeholder {
						color: #B9B9B9;
					}
					&:focus {
						border: none;

						&::placeholder {
							color: transparent;
						}
					}
				}
				&__submit {
					right: 0;
					background: none;
					color: $white;
					font-size: $xxl;
					@include centering-elements(false, true);
				}
			}
		}
	}

	&__bottom {
		background: $textColor;
		
		.inner {
			padding: 25px 0;
		}
		
		&__nav {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			&__item {
				color: $white !important;
				margin: 0 0 10px;
				padding: 0 10px;
				font-size: $xs;
				text-decoration: none !important;
				line-height: 1;
			}
		}

		&__copyright {
			color: #9a9a9a;
			font-size: $xxxs;
			font-family: 'Montserrat', sans-serif;
			margin: 15px 0 0;
		}
		
		
	}
	
}