@charset "utf-8";
/* ============================================================ */
/* .remind */
/* ============================================================ */
.remind {
	.form-area {
		max-width: 800px;
		margin: 50px auto 0;
		border: 1px solid #dcdcdc;
		padding: 50px 30px;
		background: #e6e6e6;
		
		&__item {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin: 25px 0 0;
			
			&:first-child {
				margin-top: 0;
			}
			
			&__label {
				width: 10em;
				font-weight: 500;
				position: relative;
				margin: 5px 0 0;
				
				&__sub {
					font-size: 1.3rem;
					display: block;
					font-weight: normal;
					margin: 5px 0 0;
				}
			}
			&__input {
				width: calc(100% - 12em);
			}
		}
	}
}

