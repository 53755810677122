@charset "utf-8";
/* ============================================================ */
/* .remind */
/* ============================================================ */
.remind {
	.form-area {
		margin: 30px 0 0;
		border: 1px solid #dcdcdc;
		padding: 30px 20px;
		background: #e6e6e6;
		
		&__item {
			margin: 20px 0 0;
			
			&:first-child {
				margin-top: 0;
			}
			
			&__label {
				font-weight: 500;
				
				&__sub {
					font-size: 1.3rem;
					display: block;
					font-weight: normal;
					margin: 10px 0 0;
				}
			}
			&__input {
				margin: 10px 0 0;
			}
		}
	}
}


