@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
body {
	background: $white;
}

.wrap {
	color: $textColor;
	background: $baseColor;
	font-size: 1.5rem;
	font-family: 'Noto Sans JP', sans-serif;
	line-height: 1.6;
	overflow-x: hidden;
	padding: 60px 0 0;
	
	&--pa-0 {
		padding-top: 0;
		padding-bottom: 0;
		min-height: 100vh;
	}
}

.pc-view {
	display: none !important;
}

a {
	color: $textColor;
	text-decoration: underline;
}


/* --------------------------------------------------- */
/* fix-sns */
/* --------------------------------------------------- */
.fix-sns {
	display: none;
}