@charset "utf-8";
/* ============================================================ */
/* contact */
/* ============================================================ */
.contact {
	.form-area {
		margin: 30px 0 0;
		border: 1px solid #dcdcdc;
		padding: 30px 20px;
		background: #e6e6e6;
		
		&__item {
			margin: 20px 0 0;
			
			&:first-child {
				margin-top: 0;
			}
			
			&__label {
				font-weight: 500;
				
				&__must {
					background: $textColor;
					color: $white;
					font-size: $xxxs;
					margin: 0 10px 0 0;
					padding: 1px 8px;
				}
			}
			&__input {
				margin: 10px 0 0;
			}
		}
	}

	.btn-block {
		.btn {
			margin-bottom: 10px;
		}
	}
}

