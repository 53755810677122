@charset "utf-8";
/* ============================================================ */
/* .login */
/* ============================================================ */
.login {
	.form-area {
		margin: 30px 0 0;
		border: 1px solid #dcdcdc;
		padding: 30px 20px;
		background: #e6e6e6;
		
		&__item {
			margin: 20px 0 0;
			
			&:first-child {
				margin-top: 0;
			}
			
			&__label {
				font-weight: 500;
			}
			&__input {
				margin: 10px 0 0;
			}
		}
		
		&__message {
			background: #d70025;
			text-align: center;
			padding: 5px;
			margin: -10px 0 0 0;
			color: $white;
			font-size: 1.3rem;
		}
	}
	
	.btn-block {
		.btn {
			margin-bottom: 10px;
		}
	}
	
	.new-registration {
		margin: 40px 0 0;
	}
}


