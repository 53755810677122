@charset "utf-8";

$textColor: #333;
$borderColor: #dcdcdc;
$baseColor: #f7f7f7;

$white: #fff;
$black: #000;
$red: #d30025;
$blue: #0681A1;
$purple: #5d2db3;


@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}



/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.1rem;
$xxl: 2.4rem;
$xxxl: 2.6rem;
