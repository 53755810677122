@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
}

@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
	html {
		font-size: calc(100vw / 102.4);
	}
}

.wrap {
	color: $textColor;
	background: $baseColor;
	font-size: $m;
	line-height: 1.8;
	font-family: 'Noto Sans JP', sans-serif;
	overflow-x: hidden;
	padding-top: 100px;
	padding-bottom: 200px;
	position: relative;
	min-height: 100%;
	
	@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
		padding-top: 80px;
	}
	&--pa-0 {
		padding-top: 0;
		padding-bottom: 0;
		
		@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
			padding-top: 0;
		}
	}
}

.sp-view {
	display: none !important;
}

a {
	color: $textColor;
	text-decoration: underline;
	
	&:hover {
		text-decoration: none;
	}
}


/* --------------------------------------------------- */
/* fix-sns */
/* --------------------------------------------------- */
.fix-sns {
	position: fixed;
	right: 25px;
	top: calc(50% - 50px);
	height: 90px;
	
	&__text {
		-webkit-writing-mode: vertical-rl;
		-ms-writing-mode: tb-rl;
		writing-mode: vertical-rl;
		font-size: $xs;
		font-family: 'Montserrat', sans-serif;
		line-height: 1.4;
    	padding: 0 0 65px;
		position: relative;
		
		&::after {
			content: "";
			width: 1px;
			height: 50px;
			border-right: 1px solid $textColor;
			display: inline-block;
			position: absolute;
			right: 9px;
			bottom: 6px;
		}
	}
	
	&__item {
		color: $textColor !important;
		text-decoration: none;
		@include transition;
		
		&:hover {
			text-decoration: none !important;
			@include opacity;
		}
	}
}