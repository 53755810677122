@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	.row {
		&--white {
			background: $white;
		}		
		&--border,
		&--sp-border {
			border-bottom: 1px solid $borderColor;
		}
		&--border-margin,
		&--sp-border-margin {
			margin-bottom: 30px;
			padding-bottom: 30px;
		}
		&__inner {
			max-width: 100%;
			margin: 0 auto;
			padding: 30px 15px;

			&._col2_ {
				.side {
					margin: 40px 0 0 0;
				}
			}
			&._index_ {
				padding: 15px 15px 30px;
			}
		}
		&--sp-vh {
			height: 60vh;
		}
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
} 

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	line-height: 1.2;
	margin: 0 0 25px;		
	
	&__icon {
		font-size: 2.2rem;
		margin: 0 10px 0 0;
	}
	
	&__text {
		font-size: 2.2rem;
		font-weight: 500;
		line-height: 1;
		display: block;

		&::after {
			content: attr(data-text);
			font-family: 'Montserrat', sans-serif;
			font-size: $xxs;
			font-weight: 400;
			display: block;
			margin: 10px 0 0;
		}
	}
}

.ttl02 {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	line-height: 1.2;
	margin: 0 0 20px;
	
	&__icon {
		font-size: 1.8rem;
		margin: 0 10px 0 0;
	}
	
	&__text {
		font-size: 1.8rem;
		font-weight: 500;
		line-height: 1;
		display: block;
	}
}

.ttl03 {
	font-weight: 500;
	font-size: 1.7rem;
	margin: 0 0 15px;
}


/* タイトル横にボタンが並ぶ時のボックス
/* --------------------------------------------------- */
.wrap-ttl-btn {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	margin: 0 0 25px;
	
	.ttl01,.ttl02,.ttl03,.ttl04,.ttl05,.ttl06 {
		margin: 0 0 15px;
	}
}


/* タイトル横に並び替えエリアが並ぶ時のボックス
/* --------------------------------------------------- */
.wrap-ttl-sort {
	margin: 0 0 25px;
	
	.ttl01,.ttl02,.ttl03,.ttl04,.ttl05,.ttl06 {
		margin: 0 0 15px;
	}
	
	&__sort {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
	
	& > * {
		margin-bottom: 15px;

		* {
			max-width: 100%;
		}

		img {
			outline: 1px solid transparent;

			&:not([class*="wd-a"]) {
				width: 100%;
			}
		}
	}
	
	&--mb0 > * {
		margin-bottom: 0;
	}
}


.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}
	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}
	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}
	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: #fff;

	&--gray {
		background: #e8e8e8;
	}
	&--border-gray {
		border: 1px solid $borderColor;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	table-layout: fixed;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 10px;
		background: #fff;
		border: 1px solid #ccc;
		text-align: left;
		vertical-align: middle;
	}
	.bg-gray {
		background: #666;
		color: $white;
	}
	.bg-lightgray {
		background: #f2f2f2;
		color: $textColor;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
	
	&--sp-column1 {
		border-top: 1px solid #ccc;
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-top: none;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 45px;
	margin: 0 5px;
	padding: 0 35px;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	@include radius(25);
	
	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 10px; }
	.icon-fix-right { right: 10px; }
	
	&--white {
		background: $white;
		color: $textColor !important;
		border: 1px solid $black;
		
		&:hover {
			background: $black;
			color: $white !important;
			text-decoration: none !important;
		}
	}
	&--black {
		background: $black;
		color: $white !important;
		border: 1px solid $black;
		
		&:hover {
			background: $white !important;
			color: $black !important;
			text-decoration: none !important;
		}
	}
	&--gray {
		background: #b9b9b9;
		color: $black !important;
		border: 1px solid #b9b9b9;
		
		&:hover {
			background: $white;
			color: $black;
			text-decoration: none !important;
		}
	}
	
	&--sp-sm {
		font-size: $xs;
		height: 36px;
		min-width: auto;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}

.btn-block {
	text-align: center;
	margin: 10px 0 0;
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion,
.sp-accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		font-size: $xxs;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 25px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 12px;
			background: $white;
			border: 1px solid #d5d5d5;
			color: $textColor;
			text-decoration: none;
			@include radius(3);
			
			&.current {
				background: $textColor;
				border: 1px solid $textColor;
				color: $white;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.error {
	.error-block {
		margin-top: 5px;
		color: $red;
	}
	
	input[type="text"],
	input[type="email"],
	input[type="password"],
	textarea {
		background: #fff2f3;
	}
}

input[type="text"],
input[type="email"],
input[type="hidden"],
textarea,
select {
	color: $textColor;
	font-size: 16px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
			box-sizing: content-box;
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $blue;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\e91f";
			color: $blue;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* --------------------------------------------------- */
/* tag */
/* --------------------------------------------------- */
.tag-wrap {
	display: flex;
	flex-wrap: wrap;
	margin: 10px -2.5px 0;
}

.tag {
	color: $white;
	text-align: left;
	text-decoration: none;
	background: #636363;
	margin: 0 2.5px 5px;
	padding: 4px 7px;;
	font-size: 11.5px;
	line-height: 1.2;
	display: inline-block;
	position: relative;
	@include radius(3);
	
	&__company {
		background: $blue;
	    border: 1px solid $blue;
	}
}


/* --------------------------------------------------- */
/* .label */
/* --------------------------------------------------- */
.label {
	min-width: 40px;
	color: $white;
	text-align: center;
	text-decoration: none;
	padding: 4px 7px;;
	font-size: 11.5px;
	line-height: 1;
	display: inline-block;
	position: absolute;
	@include radius(3);
	z-index: 1;
	
	&--new {
		background: $red;
		top: 5px;
		left: 5px;
		
		&::before {
			content: "\e925";
			font-family: 'icomoon' !important;
			margin: 0 2px 0 0;
		}
	}
	&--visited {
		background: $purple;
		top: 5px;
		right: 5px;
	}
}


/* --------------------------------------------------- */
/* common-contact */
/* --------------------------------------------------- */
.panel {
	background: $white;
	box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.25);
	text-decoration: none;
	position: relative;
	@include transition;
	
	&__pic {
		display: block;
		position: relative;
		padding-top: 62.5%;
		background: $white;
		overflow: hidden;

		img {
			@include centering-elements(true, true);
			@include transition;
			max-width: 100%;
			max-height: 100%;
			width: auto !important;
		}		
	}
	
	&__body {
		padding: 10px 10px 35px;
		
		&__link {
			text-decoration: none;
		}
		&__ttl {
			font-weight: 500;
			line-height: 1.5;
		}
		&__text {
			font-size: $xs;
		}
		&__date {
			color: #828181;
			font-size: $xxs;
			text-align: right;
			display: block;
			position: absolute;
			right: 10px;
			bottom: 10px;
			
			&::before {
				content: "\e907";
				font-family: 'icomoon';
				margin: 0 3px 0 0;
			}
		}
		
		&--nodate {
			padding: 10px;
		}
	}
}


/* --------------------------------------------------- */
/* post-list */
/* --------------------------------------------------- */
.post {
	display: block;
	text-decoration: none;
	padding: 10px 20px 10px 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	border-bottom: 1px solid $borderColor;
	position: relative;
	@include transition;
	
	&::after {
		content: "\e90a";
		font-family: 'icomoon';
		position: absolute;
		top: calc(50% - 15px);
		right: 0;
	}
	
	&__pic {
		width: 100px;
		height: 65px;
		margin: 0 15px 0 0;
		display: block;
		position: relative;
		background: $white;
		overflow: hidden;

		&::after {
			position: absolute;
			content: '';
			opacity: 0;
			visibility: hidden;
			@include transition;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba($black, .5);
		}

		img {
			width: 100%;
			@include centering-elements(true, true);
			@include transition;
		}
		
		&:hover {
			img {
				transform: translate(-50%, -50%) scale(1.2);
			}
			&::after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	
	&__body {
		width: calc(100% - 115px);
		
		&__date {
			color: #828181;
			font-size: $xxs;
			display: block;
			
			&::before {
				content: "\e907";
				font-family: 'icomoon';
				margin: 0 3px 0 0;
			}
		}
		
		&__link {
			text-decoration: none;
			
			&__company {
				font-weight: 500;
			}
		}
	}
}


/* --------------------------------------------------- */
/* share-area */
/* --------------------------------------------------- */
.share-area {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 40px 0 0;

	.social-button {
		&__list {
			list-style-type: none;
			line-height: 1;
			font-size: 12px;

			&__item {
				display: inline-block;
				margin: 0 10px 15px 0;

				.__link {
					display: inline-block;
					padding: 9px 15px 7px;
					border-radius: 4px;
					text-decoration: none;
					color: #ffffff;
					vertical-align: middle;

					&::before {
						display: inline-block;
						font-family: 'social-button' !important;
						font-style: normal;
						font-weight: normal;
						line-height: 1;
						font-size: 130%;
						margin-right: 10px;
						vertical-align: middle;
					}
					&:hover {
						opacity: 0.7;
					}
				}

				&._twitter-tweet_ {
					.__link {
						background: #1b95e0;
						&::before {
							content: "\ea96";
						}
					}
				}
				&._facebook-share_ {
					.__link {
						background: #3a5795;
						&::before {
							content: "\ea91";
						}
					}
				}
				&._hatena-bookmark_ {
					.__link {
						background: #00a4de;
						&::before {
							content: "\e900";
						}
					}
				}
				&._pocket-save_ {
					.__link {
						background: #ef4156;
						&::before {
							content: "\e902";
						}
					}
				}
				&._line-send_ {
					.__link {
						background: #00c300;
						&::before {
							content: "\e901";
						}
					}
				}
				&._rss-feed_ {
					.__link {
						background: #ee802f;
						&::before {
							content: "\e926";
							font-family: 'icomoon' !important;
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
	
	&._side_ {
		margin: 0;
		
		.share-area__inner {
			width: 100%;
			padding: 10px 10px 5px;
			background: $white;
			border: 1px solid #e6e6e6;

			.social-button {
				&__list {
					&__item {
						margin: 0 5px 5px 0;
					}
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* company-information */
/* --------------------------------------------------- */
.company-information {
	&__item {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 15px;
		border-bottom: 1px solid $borderColor;
		
		&__ttl {
			width: 115px;
			font-weight: 700;
		}
		&__content {
			width: calc(100% - 115px;)
		}
	}
}


/* --------------------------------------------------- */
/* sort */
/* --------------------------------------------------- */
.sort {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	
	&__item {
		margin: 5px 15px 5px 0;
		
		&__ttl {
			font-size: $s;
			font-weight: 500;
			margin: 0 5px 0 0;
		}
		
		&__select {
			height: auto;
			font-size: $s;
			padding: 3px 0 3px 5px;
			border: 1px solid $borderColor;
			background: $white;
		}
	}
}


/* --------------------------------------------------- */
/* upload-content */
/* --------------------------------------------------- */
.upload-content {
	display: flex;
	flex-direction: column;
	border: 1px solid #dcdcdc;
	
	&__side {
		background: #e6e6e6;
	}
	&__main {
		background: $white;
		padding: 25px;
	}
}


/* --------------------------------------------------- */
/* upload-nav */
/* --------------------------------------------------- */
.upload-nav {
	&__item {
		border-bottom: 1px solid #d5d5d5;
	}
	&__link {
		width: 100%;
		padding: 15px;
		display: inline-flex;
		align-items: center;
		text-decoration: none;
		position: relative;
		@include transition;
		
		&::before {
			content: "\e90a";
			font-family: 'icomoon';
			margin-right: 3px;
		}
		&:hover {
			background: #d9d9d9;
		}
	}
}


/* --------------------------------------------------- */
/* file-upload */
/* --------------------------------------------------- */
.file-upload {
	display: flex;
	flex-direction: column;
	
	.btn {
		width: 50%;
		margin: 20px auto 0;
	}
}


/* --------------------------------------------------- */
/* alert */
/* --------------------------------------------------- */
.alert {
	font-size: $s;
	color: #397ee3;
    padding: 12px 15px;
    border: 1px solid #9EC5FE;
    background: #E2EFFF;
	
	&--error {
		border-color: #F1AEB5;
		background: #fff2f3;
		color: #f16767;
	}
}



