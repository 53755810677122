@charset "utf-8";
/* ============================================================ */
/* .movie */
/* ============================================================ */
	
// 動画詳細ページ
.movie_detail {

	.ttl-movie {
		border-bottom: 1px solid $borderColor;
		margin: 0 0 15px;
		padding: 0 0 15px;
		position: relative;

		&::after {
			content: "";
			width: 80px;
			height: 1px;
			border-bottom: 1px solid $textColor;
			position: absolute;
			bottom: -1px;
			left: 0;
		}
		
		&__ttl {
			font-size: $xxxl;
			line-height: 1.6;
		}
	}

	.movie-info {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.movie-share {
			.social-button {
				&__list {
					list-style-type: none;
					line-height: 1;
					font-size: 12px;

					&__item {
						display: inline-block;
						margin: 0 0 0 5px;

						.__link {
							display: inline-block;
							padding: 9px 10px 7px;
							border-radius: 4px;
							text-decoration: none;
							color: #ffffff;
							vertical-align: middle;

							&::before {
								display: inline-block;
								font-family: 'social-button' !important;
								font-style: normal;
								font-weight: normal;
								line-height: 1;
								font-size: 130%;
								margin-right: 10px;
								vertical-align: middle;
							}
							&:hover {
								opacity: 0.7;
							}
						}
						&._twitter-tweet_ {
							.__link {
								background: #1b95e0;
								&::before {
									content: "\ea96";
								}
							}
						}
						&._facebook-share_ {
							.__link {
								background: #3a5795;
								&::before {
									content: "\ea91";
								}
							}
						}
						&._hatena-bookmark_ {
							.__link {
								background: #00a4de;
								&::before {
									content: "\e900";
								}
							}
						}
						&._pocket-save_ {
							.__link {
								background: #ef4156;
								&::before {
									content: "\e902";
								}
							}
						}
						&._line-send_ {
							.__link {
								background: #00c300;
								&::before {
									content: "\e901";
								}
							}
						}
						&._rss-feed_ {
							.__link {
								background: #ee802f;
								&::before {
									content: "\e928";
									font-family: 'icomoon' !important;
									margin-right: 5px;
								}
							}
						}
					}
				}
			}
		}
	}
	
	.movie-date {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
	}
	
	.movie-wrap,
	.movie-text,
	.movie-tag {
		margin: 40px 0 0;
	}
}

