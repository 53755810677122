@charset "utf-8";

&-list {
	&Number {
		list-style: decimal outside;
		padding-left: 20px;
	}

	&Disc {
		list-style-type: disc;
		padding-left: 20px;

	}
}
@media print, screen and (min-width: 767.5px) {

}

@media screen and (max-width: 767.499px) {

}

