@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer {
    width: 100%;
	color: #e3e3e3;
	text-align: center;
	line-height: 1.6;
	position: absolute;
    bottom: 0;
    left: 0;
	
	.inner {
		max-width: 1240px;
		margin: 0 auto;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	&__top {
		background: #484848;
		
		.inner {
			height: 115px;
		}
		
		&__left {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
		
		&__logo {
			width: 170px;
			margin: 0 25px 0 0;
		}
		
		&__sns {
			&__item {
				width: 35px;
				height: 35px;
				text-decoration: none !important;
				background: $white;
				color: $textColor !important;
				border: 1px solid $white;
				@include radius(18);
				@include transition;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				margin: 0 10px 0 0;
				
				&:hover {
					background: $textColor;
					color: $white !important;
				}
			}
		}
		
		&__search {
			border: 1px solid #727272;
			@include radius(4);
			padding: 8px;
			
			&__body {
				position: relative;
				display: flex;
				align-items: center;
				
				&__input[type="text"] {
					width: calc(100% - 50px);
					margin: 0 50px 0 0;
					padding: 0 0 0 10px;
					height: auto;
					background: #484848;
					border: none;
					color: $white;
					font-size: $xxs;

					&::placeholder {
						color: #B9B9B9;
					}
					&:focus {
						border: none;

						&::placeholder {
							color: transparent;
						}
					}
				}
				&__submit {
					right: 0;
					background: none;
					color: $white;
					font-size: $xxl;
					@include centering-elements(false, true);
				}
			}
		}
	}

	&__bottom {
		background: $textColor;
		
		.inner {
			height: 85px;
			
			@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
				align-items: flex-start;
				flex-direction: column;
				justify-content: center;
			}
		}
		&__nav {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			
			@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
				margin: 0 0 10px;
			}

			&__item {
				color: $white !important;
				padding: 0 10px;
				font-size: $xs;
				text-decoration: none !important;
				line-height: 1;

				&:hover {
					text-decoration: underline !important;
				}
				@media screen and (min-width: 767.5px) and (max-width: 1024.4px) {
					padding: 0 10px 0 0;
				}
			}
		}
		&__copyright {
			color: #9a9a9a;
			font-size: $xxxs;
			font-family: 'Montserrat', sans-serif;
			text-align: left;
		}
	}
}