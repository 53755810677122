@charset "utf-8";
/* ============================================================ */
/* .about */
/* ============================================================ */
.about {
	
	.catchbox {
		margin: 0 0 25px;
		
		&__message {
			background: $white;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 40px 15px 30px;
			position: relative;
			
			&::before,
			&::after {
				content: "\e924";
				font-family: 'icomoon';
				font-size: 3.5rem;
				line-height: 1;
				color: #dadada;
				display: inline-block;
				position: absolute;
			}

			&::before {
				transform: scale(-1, -1);
				left: 10px;
				top: 10px;
			}
			&::after {
				right: 10px;
				top: 10px;
			}
			
			
			&__text {
				text-align: center;
				font-size: 1.7rem;
			}
		}
	}
	
}

