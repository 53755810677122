@charset "utf-8";
/* ============================================================ */
/* .login */
/* ============================================================ */
.login {
	.form-area {
		max-width: 800px;
		margin: 50px auto 0;
		border: 1px solid #dcdcdc;
		padding: 50px 30px;
		background: #e6e6e6;
		
		&__item {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin: 25px 0 0;
			
			&:first-child {
				margin-top: 0;
			}
			
			&__label {
				width: 10em;
				font-weight: 500;
				position: relative;
				margin: 5px 0 0;
			}
			&__input {
				width: calc(100% - 12em);
			}
		}
		
		&__message {
			background: #d70025;
			text-align: center;
			padding: 5px 0;
			margin: -25px 0 35px 0;
			color: $white;
		}
	}
	
	.btn-block {
		.btn {
			margin-bottom: 10px;
		}
	}
	
	.new-registration {
		margin: 60px 0 0;
	}
}

