@charset "utf-8";
/* ============================================================ */
/* .company */
/* ============================================================ */

/* 企業情報詳細ページのタイトル
/* --------------------------------------------------- */
.ttl-company {
	border-bottom: 1px solid $borderColor;
	margin: 0 0 15px;
	padding: 0 0 25px;
	position: relative;
	display: block;
	
	&::after {
		content: "";
		width: 80px;
		height: 1px;
		border-bottom: 1px solid $textColor;
		position: absolute;
		bottom: -1px;
		left: 0;
	}
	&__inner {
		font-size: $xxl;
		font-weight: 500;
	}
	&__code {
		font-size: $l;
		font-family: 'Montserrat', sans-serif;
		line-height: 1;
		margin: 0 0 10px;
		position: relative;
		display: block;

		&::after {
			content: "";
			border-top: 1px solid $textColor;
			width: 25px;
			height: 1px;
			display: inline-block;
			margin: 0 0 6px 15px;
		}
	}
	&__name {
		display: block;
		line-height: 1.5;
		margin: 0 0 15px;
		
		&__en {
			display: block;
			font-size: $s;
			color: #8F8F8F;
			font-family: 'Montserrat', sans-serif;
			margin: 5px 0 0;
		}
	}	
	&__message {
		background: $textColor;
		width: 100%;
		font-size: 1.3rem;
		text-align: center;
		color: $white;
		margin: -15px 0 20px;
		padding: 3px 0;
		opacity: 1;
		@include transition;
		
		&__inner {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}
		&__btn {
			position: absolute;
			top: 0;
			right: 10px;
			cursor: pointer;
			@include transition;
			background: none;
			color: $white;
		}
		
		&--close {
			display: none;
		}
	}
}

.company {
	.company-information {
		&__item {
			&__content {
				&__relation-link {
					&__item {
						padding: 0 18px 0 15px;
						position: relative;

						&::before {
							content: "\e90a";
							font-family: 'icomoon' !important;
							font-size: $s;
							position: absolute;
							left: 0;
							top: 2px;
						}
						&::after {
							content: "\e90c";
							font-family: 'icomoon' !important;
							font-size: 1.25rem;
							position: absolute;
							right: 0;
							top: 3px;
						}
					}
				}
			}
		}
	}
}